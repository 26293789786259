import React from 'react'
import moment from "moment";
import { useState, useEffect } from 'react';
import './Content.css'
import { getWinnerNamesPerDay, getPromoName } from '../api/bonusAPI';
import { format, utcToZonedTime } from 'date-fns-tz';
import Next from './navigate_next_black_24dp.svg';
import fb from './facebook.png';
import ig from './instagram.png';
import telegram from './telegram.png';
import wa from './whatsapp.png';

function Dailybonus() {
  const [data, setData] = useState([]);
  
  const getData = async () => {
    try {
      const data = await Promise.all(
        Array.from({ length: 5 }, (_, i) => moment().subtract(i, 'days')).map(async (day) => {
          const year = day.format('YYYY');
          const month = day.format('MM');
          
          const [winners, promoName] = await Promise.all([
            getWinnerNamesPerDay(day),
            getPromoName(year, month),
          ]);
          
          return {
            date: day,
            promoName,
            winners,
          };
        })
      );
        
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };
  

  useEffect(() => {
    getData();
  }, []);

  const now = new Date();
  const wibNow = utcToZonedTime(now, 'Asia/Jakarta');
  const currentHour = parseInt(format(wibNow, 'HH'));

  const tables = data.map(({ date, promoName, winners }) => {
    const day = date.format('YYYY-MM-DD');
    const formattedDate = date.format('DD MMMM YYYY');

    const pagiData = winners.filter(item => {
      const itemDate = `${item.wYear}-${item.wMonth.padStart(2, '0')}-${item.wDate.padStart(2, '0')}`;
      return itemDate === day && item.winShiftLabel === 'pagi';
    });
    
    const soreData = winners.filter(item => {
      const itemDate = `${item.wYear}-${item.wMonth.padStart(2, '0')}-${item.wDate.padStart(2, '0')}`;
      return itemDate === day && item.winShiftLabel === 'sore';
    });
    

    const isToday = day === moment().format('YYYY-MM-DD');

    return (
      <div key={day}>
      <div id="contact-links" className='container mt-5'>
          <div id="trigger"><img className='image-next' alt='next' src={Next}/></div>
          <div id="content">
              <h3 className='contact-us'>
                <img className='img-contact' src="https://www.clipartmax.com/png/small/92-926082_contact-us-contact-us-icon-png.png" alt="Contact Us - Contact Us Icon Png @clipartmax.com"/>
                <span className='text-contact'>CONTACT US</span>
                </h3>
                <br/>
              <a href="https://api.whatsapp.com/send?phone=639690809922" target="_blank" rel="noreferrer">
                <img src={wa} className='wa-img' alt='wa-img'/>
                <span className='wa-text'>+639981797769</span>
                </a>
              <a href="https://www.facebook.com/groups/togel4dofficial/?mibextid=6NoCDW" target="_blank" rel="noreferrer">
              <img src={fb} className='fb-img' alt='wa-img'/>
                <span className='fb-text'>TOGEL4D OFFICIAL</span>
                </a>
              <a href="https://t.me/togel4dofficial" target="_blank" rel="noreferrer">
              <img src={telegram} className='tele-img' alt='wa-img'/>
                <span className='fb-text'>TOGEL4D OFFICIAL</span>
                </a>
              <a href="https://www.instagram.com/togel4dofficial/" target="_blank" rel="noreferrer">
              <img src={ig} className='ig-img' alt='wa-img'/>
                <span className='ig-text'>TOGEL4DOFFICIAL</span>
                </a>
          </div>
      </div>     
        <h1>{promoName} - 6 Sore</h1>
        <table>
          <thead>
            <tr>
              <th colSpan={5}>Winner {formattedDate}</th>
            </tr>
          </thead>
          <tbody>
          {((isToday && (currentHour >= 18)) || !isToday) && soreData.length > 0 ? (
          soreData.reduce((acc, item, index) => {
            if (index % 5 === 0) acc.push([]);
            acc[acc.length - 1].push(item);
            return acc;
          }, []).map((group, index) => (
            <tr key={index}>
            {group.map((item, subIndex) => {
              const censoredHTML = item.loginIdCensored.replace(/\*\*/g, '<span class="text-censored">**</span>');
              return (
                <td key={subIndex}><span dangerouslySetInnerHTML={{ __html: censoredHTML }} /></td>
              );
            })}
            </tr>
          ))
          ): (
          <tr>
            <td colSpan={5}>No Data</td>
          </tr>
          )}
          </tbody>
        </table>
        <h1>{promoName} - 6 Pagi</h1>
        <table>
          <thead>
            <tr>
              <th colSpan={5}>Winner {formattedDate}</th>
            </tr>
          </thead>
          <tbody>
          {((isToday && currentHour >= 6) || !isToday) && pagiData.length > 0 ? (
          pagiData.reduce((acc, item, index) => {
            if (index % 5 === 0) acc.push([]);
            acc[acc.length - 1].push(item);
            return acc;
          }, []).map((group, index) => (
            <tr key={index}>
            {group.map((item, subIndex) => {
              const censoredHTML = item.loginIdCensored.replace(/\*\*/g, '<span class="text-censored">**</span>');
              return (
                <td key={subIndex}><span dangerouslySetInnerHTML={{ __html: censoredHTML }} /></td>
              );
            })}
            </tr>
          ))
          ) : (
          <tr>
            <td colSpan={5}>No Data</td>
          </tr>
          )
        }
          </tbody>
        </table>
      </div>
    );
  });


  return (
    <section id='daily-bonus' className='container mt-5'>
      <div className='table-daily'>
        {tables}
      </div>
    </section>
  );
}

export default Dailybonus
