import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
    <div className='copyright'>
     Copyright &copy; {new Date().getFullYear()} TOGEL4D.COM ALL RIGHTS RESERVED V1.11.23-228
    </div>
    </footer>
  )
}

export default Footer