import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Dailybonus from './components/Content/Dailybonus';
import Monthlybonus from './components/Content/Monthlybonus';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Header />
          <div className="main-content">
            <Routes>
              <Route index path="/" element={<Dailybonus />} />
              <Route path="/daily-bonus" element={<Dailybonus />} />
              <Route path="/monthly-bonus" element={<Monthlybonus />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
